<template>
  <div class="page">
    <div class="header bg-primary">
      <q-toolbar class="q-pa-none">
        <q-img src="src/assets/svg/logo.png" class="logo" />
        <q-space />
        <q-btn stretch no-caps flat label="Home" class="text-weight-bold header-btn" />
        <q-btn @click="linkto('product')" stretch no-caps flat label="Product" class="text-weight-regular header-btn" />
        <q-btn @click="linkto('about')" stretch no-caps flat label="About" class="text-weight-regular header-btn"/>
        <q-btn @click="linkto('contact')" stretch no-caps flat label="Contact" class="text-weight-regular header-btn"/>
        <q-btn-dropdown size="lg" flat round dense dropdown-icon="menu" class="q-mr-sm drop-down-btn">
          <q-list class="custom-bg">
            <q-item clickable v-close-popup @click="linkto('product')">
              <q-item-section>
                <q-item-label>Product</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="linkto('product')">
              <q-item-section>
                <q-item-label>About</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="linkto('product')">
              <q-item-section>
                <q-item-label>Contact</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </div>
    <div class="body flex justify-center">
      <div>
        <div class="slogan-content">
          <p class="slogan-content-text-title">Make <br> Telehealth Products <br> With Confidence</p>
          <div class="slogan-content-body">
            <span class="slogan-content-text-body">Telehealth is essential to our health care delivery system, entitling health care providers to connect with patients and consulting practitioners across vast distances. <br> <br> In 2015, the American Hospital Association reported on a telemedicine program that saved 11% in costs and more than tripled ROI for investors.</span>
          </div>
        </div>
      </div>
      <img src="src/assets/svg/image1.svg" class="slogan-image">
    </div>
    <div class="flex flex-center">
      <q-card class="card-services">
        <q-card-section class="flex flex-center card-title">
          OUR VALUABLE SERVICES
        </q-card-section>
        <q-card-section class="flex flex-center">
          <div class="text-center">
            <q-img src="src/assets/svg/service-1.svg" class="service-image" />
            <p class="service-text">Electronic Medical Record</p>
          </div>
          <div class="text-center">
            <q-img src="src/assets/svg/service-2.svg" class="service-image" />
            <p class="service-text">Medical Assessment Tools</p>
          </div>
          <div class="text-center">
            <q-img src="src/assets/svg/service-3.svg" class="service-image" />
            <p class="service-text">DICOM Reader</p>
          </div>
        </q-card-section>
        <q-card-section class="text-center card-consult-btn">
          <q-btn @click="consultDialog = true" label="Consult me" no-caps color="primary" text-color="black" class="consult-btn"></q-btn>
        </q-card-section>
      </q-card>
    </div>
    <div class="card-why">
      <p class="card-title text-center">WHY NEED US?</p>
      <div class="card-why-content flex justify-center">
        <div class="card-why-image">
          <div class="why-image-center">
            <q-img src="src/assets/svg/why-1.svg" class="why-image" />
          </div>
          <p class="why-title">A recipe for success as a telehealth provider</p>
          <p class="why-body">We bring you essential solutions in telehealth industry with available templates and vital tools.</p>
        </div>
        <div class="card-why-image">
          <div class="why-image-center">
            <q-img src="src/assets/svg/why-2.svg" class="why-image" />
          </div>
          <p class="why-title">Easy to integrate on multiple platforms</p>
          <p class="why-body">Our solutions are able to integrate in multi-platform. Both doctors and patients can access the information they would like to search for.</p>
        </div>
        <div class="card-why-image">
          <div class="why-image-center">
            <q-img src="src/assets/svg/why-3.svg" class="why-image" />
          </div>
          <p class="why-title why-title-1-line">Insightful</p>
          <p class="why-body">Doctors can access patient history, gaps in care, prescription guidelines, referral instructions, and wellness programs at once during online checkup.</p>
        </div>
        <div class="card-why-image">
          <div class="why-image-center">
            <q-img src="src/assets/svg/why-4.svg" class="why-image" />
          </div>
          <p class="why-title why-title-1-line">Optimize the investment</p>
          <p class="why-body">We help you increase value for your stakeholders. Together with our available and professional system, your business will be enhanced.</p>
        </div>
      </div>
    </div>
    <div class="flex flex-center">
      <q-card class="card-case">
        <p class="card-title text-center">OUR USE CASE</p>
        <div class="row margin-case">
          <p class="col-3 case-title">Brief</p>
          <div class="col-9 case-content-body">
            <p class="case-subtitle">The client would like to build a platform to create EHR with some specific requests as follows:</p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Follow the international standard</span> 
            </p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Allow staffs input and translate medical report</span> 
            </p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Provide assessments from doctors</span> 
            </p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Deliver Care Plan as requested</span> 
            </p>
          </div>
        </div>
        <q-separator class="margin-separator" />
        <div class="row margin-case">
          <p class="col-3 case-title">Deliverables</p>
          <div class="col-9 case-content-body">
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">A Backend System for Operator</span> 
            </p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Medical Accessible Channel (Mobile and Desktop) for End-users</span> 
            </p>
            <p class="row">
              <q-img src="src/assets/svg/dot.svg" class="dot" />
              <span class="case-body col">Medical Accessible Channel (Mobile and Desktop) for Doctors</span> 
            </p>
          </div>
        </div>
        <q-separator class="margin-separator" />
        <div class="row margin-case">
          <p class="col-3 case-title">Role</p>
          <div class="col-9 case-content-body">
            <p class="row">
              <span class="case-subtitle col">Consultant, Scope Definition, Product Designer, Product Developer</span> 
            </p>
          </div>
        </div>
        <q-separator class="margin-separator" />
        <div class="row margin-case">
          <p class="col-3 case-title">Principles</p>
          <div class="col-9 case-content-body-4">
            <div class="flex justify-between">
              <div class="card-principle">
                <q-img fit="scale-down" src="src/assets/svg/fhir.png" class="principle-image" />
                <span class="case-body col">Standard</span> 
              </div>
              <div class="card-principle">
                <q-img fit="scale-down" src="src/assets/svg/aws.svg" class="principle-image" />
                <span class="case-body col">Framework</span> 
              </div>
              <div class="card-principle">
                <q-img fit="scale-down" src="src/assets/svg/jira.png" class="principle-image" />
                <span class="case-body col">Workflow</span> 
              </div>
              <div class="card-principle">
                <q-img fit="scale-down" src="src/assets/svg/flutter.png" class="principle-image" />
                <span class="case-body col">Cross-platform</span> 
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </div>
    <div class="card-demo">
      <p class="card-title text-center">UI/UX DEMO</p>
      <div class="card-demo-image">
        <q-img fit="fill" src="src/assets/svg/demo-1.png" class="demo-image-1" />
        <q-img fit="fill" src="src/assets/svg/demo-2.png" class="demo-image-2" />
        <q-img fit="fill" src="src/assets/svg/demo-3.png" class="demo-image-3" />
      </div>
      <div class="card-download text-center">
        <q-btn @click="downloadDialog = true" label="Download full use case sample" no-caps color="primary" text-color="black" class="download-btn"></q-btn>
      </div>
    </div>
    <q-card class="footer">
      <div class="flex justify-between q-gutter-lg">
        <q-img fit="fill" src="src/assets/svg/logo-footer.png" class="footer-logo" />
        <span class="footer-text"> <q-img fit="fill" src="src/assets/svg/phone.png" class="footer-icon" /> Phone: (+84)909 189 329</span>
        <a href="mailto:sales@sk-global.biz" style="text-decoration: none">
          <span class="footer-text"> <q-img fit="fill" src="src/assets/svg/email.png" class="footer-icon" />Email: sales@sk-global.biz</span>
        </a>
        <span class="footer-text"> <q-img fit="fill" src="src/assets/svg/address.png" class="footer-icon" />Address: 74/13/4 Truong Quoc Dung, <br>Ward 10, Phu Nhuan District, <br>Ho Chi Minh City </span>
      </div>
      <div class="footer-right footer-text text-center">
        © 2022 Sk-Global. All rights reserved.
      </div>
    </q-card>
  </div>
  <q-dialog v-model="consultDialog">
    <q-card class="consult-card">
      <div class="flex justify-between consult-body">
        <q-btn @click="consultDialog = false" size="sm" icon="close" class="close-btn" outline round />
        <div class="consult-intro">
          <p class="consult-title">Let’s build <br>a telehealth product together!</p>
          <p class="why-body mb-mb-none">Drop us an email: <a href="mailto:sales@sk-global.biz" style="color: #00A4FF">sales@sk-global.biz</a></p>
          <div class="text-center">
            <q-img src="src/assets/svg/consult-img.svg" class="consult-img" />
          </div>
        </div>
        <div class="consult-form">
          <span class="consult-text-body">Name *</span>
          <q-input outlined v-model="name" placeholder="Type your name" class="input" dense />
          <span class="consult-text-body">Company *</span>
          <q-input outlined v-model="company" placeholder="Type your company" class="input" dense />
          <span class="consult-text-body">Email *</span>
          <q-input outlined v-model="email" placeholder="Type your email" class="input" dense />
          <span class="consult-text-body">How can we help you?</span>
          <q-input outlined type="textarea" v-model="text" placeholder="Message" class="input" dense />
          <span class="consult-text-title">Service</span>
          <div>
            <q-checkbox v-model="services" val="Electronic Medical Report" label="Electronic Medical Report" class="consult-text-body" />
          </div>
          <div>
            <q-checkbox v-model="services" val="Medical Assessment Tools" label="Medical Assessment Tools" class="consult-text-body" />
          </div>
          <div>
            <q-checkbox v-model="services" val="DICOM Reader" label="DICOM Reader" class="consult-text-body" />
          </div>
          <div class="card-send-btn">
            <q-btn @click="clickSendMail" label="Send" no-caps color="primary" text-color="black" class="consult-btn"></q-btn>
          </div>
        </div>
      </div>

      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </q-dialog>
  <q-dialog v-model="downloadDialog">
    <q-card class="download-card">
      <div class="download-body">
        <q-btn @click="downloadDialog = false" size="sm" icon="close" class="close-btn" outline round />
        <div class="download-intro text-center">
          <span class="why-body">Interesting in our use case?</span>
          <p class="consult-title">Download full sample here!</p>
          <div class="download-form">
            <span class="consult-text-body">Your email *</span>
            <q-input outlined v-model="email" placeholder="Type your email" class="download-input" dense />
          </div>
          <q-btn @click="downloadPdf" label="Download now" no-caps color="primary" text-color="black" class="consult-btn"></q-btn>
        </div>
      </div>
      
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { useQuasar } from 'quasar'
import gsheet from 'src/services/google-sheet'

export default defineComponent({
  name: 'RootPage',
  setup() {
    const initialState = {
      consultDialog: false,
      downloadDialog: false,
      loading: false,
      name: '',
      company: '',
      email: '',
      text: '',
      services: [],
    }
    const state = reactive({...initialState})

    const $q = useQuasar()

    const clickSendMail = async () => {
      try {
        if (!state.name) throw 'Please input your name'
        if (!state.company) throw 'Please input your company'
        if (!state.email) throw 'Please input your email'
        if (!validateEmail(state.email)) throw 'Please input correct email'
        if (state.services.length === 0) throw 'Please choose at least 1 service'
        let data = ''
        state.services.map((service, index) => {
          data += (index + 1) + `. ${service}\n`
        })
        const email = `<a href="mailto:${state.email}">${state.email}</a>`
        const body = `<html>
        <body>
          <table style="width: 500px; font-family: sans-serif; font-size: 14px;" border="1">
            <tr style="height:32px">
              <th align="right" style="width:150px;padding-right:5px">Name:</th>
              <td align="left" style="padding-left:5px;line-height:20px">${state.name}</td>
            </tr>
            <tr style="height:32px">
              <th align="right" style="width:150px;padding-right:5px">Company:</th>
              <td align="left" style="padding-left:5px;line-height:20px">${state.company}</td>
            </tr>
            <tr style="height: 32px;">
                <th align="right" style="width: 150px; padding-right: 5px;">Email:</th>
                <td align="left" style="padding-left: 5px; line-height: 20px;">${email}</td>
              </tr>
            <tr style="height:32px">
              <th align="right" style="width:150px;padding-right:5px">Services:</th>
              <td align="left" style="padding-left:5px;line-height:20px; white-space: break-spaces">${data}</td>
            </tr>
            <tr style="height:32px">
              <th align="right" style="width:150px;padding-right:5px">Message:</th>
              <td align="left" style="padding-left:5px;line-height:20px">${state.text}</td>
            </tr>
          </table>
        </body>
        </html>`
        await sendMail(body, state.name);
      } catch (err) {
        $q.notify({
          type: 'negative',
          position: 'top-right',
          message: err,
        });
      }
    }
    const sendMail = async (body, name) => {
      try{
        state.loading = true
        const payload = {
          to: ["sales@sk-global.biz"],
          // to: ["nhtam@sk-global.biz"],
          subject: `Inquiry from ${name}`,
          html: body
        }
        await fetch("https://zubwll62ijppuc7pouydfd4hnm0rcbgs.lambda-url.ap-southeast-1.on.aws/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "LcY6L6t2CN"
            },
            body: JSON.stringify(payload)
          }
        )
        $q.notify({
          color: 'white',
          position: 'top-right',
          message: '<img src="src/assets/svg/send-email.png" style="width: 300px">',
          html: true
        });
        resetDefault();
      } catch (err) {
        state.loading = false
        console.log(err)
      }
    }
    const resetDefault = () => {
      state.consultDialog = false,
      state.downloadDialog = false,
      state.loading = false,
      state.name = '',
      state.company = '',
      state.email = '',
      state.text = '',
      state.services = []
    }
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const downloadPdf = async () => {
      try {
        if (!state.email) throw 'Please input your email'
        if (!validateEmail(state.email)) throw 'Please input correct email'
        state.loading = true
        await fetch("https://u7hzmwnegrwttkhju4vizt7aqy0qjdga.lambda-url.ap-southeast-1.on.aws/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "LcY6L6t2CN"
            },
            body: JSON.stringify({email: state.email})
          }
        )
        let link = document.createElement('a');
        link.href = 'src/assets/pdf/ehr-use-case.pdf';
        link.download = 'ehr-use-case-introduction.pdf';
        link.dispatchEvent(new MouseEvent('click'));
        resetDefault();
        $q.notify({
          color: 'white',
          position: 'top-right',
          message: '<img src="src/assets/svg/downloaded.png" style="width: 300px">',
          html: true
        });
      } catch (err) {
        state.loading = false
        $q.notify({
          type: 'negative',
          position: 'top-right',
          message: err,
        });
      }
    }

    const linkto = (link) => {
      if (link === 'about') window.open("https://sk-global.biz/");
      if (link === 'product') window.open("https://sk-global.biz/#products");
      if (link === 'contact') window.open("https://sk-global.biz/#contact");
    }

    return {
      ...toRefs(state),
      downloadPdf,
      clickSendMail,
      linkto,
    }
  },
})
</script>

<style lang="scss">
  @import "src/assets/app.scss";
</style>